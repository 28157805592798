import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/seo';
import SectionHeroSlider from '../components/sections/SectionHeroSlider/SectionHeroSlider';
import SectionBreadCrumbs from '../components/sections/SectionBreadCrumbs/SectionBreadCrumbs';
import SectionAlternateGrid from '../components/sections/SectionAlternateGrid/SectionAlternateGrid';
import RecipeCollection from '../components/sections/RecipeCollection/RecipeCollection';
import HeroComponent from '../components/sections/HeroComponent/HeroComponent';

const RecipeLanding = ({
    pageContext,
    location,
    data: {
        contentfulPageRecipeLanding: {
            seoTitle,
            seoDescription,
            sections,
            pageTitle,
            missingH1,
            slug
        },
        allContentfulPageRecipe
    }
}) => {

    return (
        <div className='content triangle triangle-left'>
            {
                missingH1
                    ? <h1 style={{ display: 'none' }}>{seoTitle}</h1>
                    : null
            }
            <Seo
                title={seoTitle}
                description={seoDescription?.seoDescription}
                lang={pageContext.node_locale}
                slug={slug}
            />
            {
                sections.map((section, index) => {
                    switch (section.__typename) {
                        case "ContentfulComponentHero":
                            return index == 0 ? (
                                <HeroComponent data={section} index={index} key={index} />
                            ) : (
                                <div key={index}></div>
                            )
                        case "ContentfulSectionHeroSlider":
                            return (
                                <SectionHeroSlider data={section} key={index} index={index} />
                            )
                        case "ContentfulSectionBreadCrumbs":
                            return (
                                <SectionBreadCrumbs data={section} title={pageTitle} key={index} />
                            )
                        case "ContentfulSectionAlternateGrid":
                            return (
                                <SectionAlternateGrid data={section} key={index} />
                            )
                        case "ContentfulComponentRecipeCollection":
                            return (
                                <RecipeCollection data={section} key={index} />
                            )
                    }
                })
            }
        </div>
    )
}

export const RecipeLandingQuery = graphql`
    query RecipeLandingQuery( $id: String! ){
        contentfulPageRecipeLanding(id: {eq: $id}){
            seoTitle
            slug
            seoDescription {
                seoDescription
            }
            pageTitle
            missingH1
            sections {
                __typename
                ...sectionHeroSlider
                ...sectionsBreadcrumbs
                ...alternateGrid
                ...recipeCollection
                ...componentHero
            }
        }
    }
`

export default RecipeLanding